import * as _tls2 from "tls";

var _tls = "default" in _tls2 ? _tls2.default : _tls2;

var exports = {};
const tls = _tls;

exports = (options = {}, connect = tls.connect) => new Promise((resolve, reject) => {
  let timeout = false;
  let socket;

  const callback = async () => {
    await socketPromise;
    socket.off("timeout", onTimeout);
    socket.off("error", reject);

    if (options.resolveSocket) {
      resolve({
        alpnProtocol: socket.alpnProtocol,
        socket,
        timeout
      });

      if (timeout) {
        await Promise.resolve();
        socket.emit("timeout");
      }
    } else {
      socket.destroy();
      resolve({
        alpnProtocol: socket.alpnProtocol,
        timeout
      });
    }
  };

  const onTimeout = async () => {
    timeout = true;
    callback();
  };

  const socketPromise = (async () => {
    try {
      socket = await connect(options, callback);
      socket.on("error", reject);
      socket.once("timeout", onTimeout);
    } catch (error) {
      reject(error);
    }
  })();
});

export default exports;